<template>
  <b-container id="mainHome" fluid>
    <!-- DIVIDER -->
    <b-row>
      <b-col sm="2">
        <sidebarcontent />
      </b-col>
      <b-col sm="10">
        <!-- SECTION search bar -->
        <b-row>
          <b-col sm="12">
            <!-- <b-row class="bg-danger pt-3">
             
            </b-row> -->
            <b-row style="height:20em" class="  mb-3 custom-bg">
              <b-col cols="6" class="d-flex flex-column justify-content-center">
                <b-icon
                  variant="light"
                  class="h1 d-md-none"
                  style="margin:0"
                  v-b-toggle.sidebar-backdrop
                  icon="list"
                ></b-icon>
              </b-col>
              <b-col cols="6" class="mt-4">
                <b-button
                  class="d-block d-md-none"
                  style="border: solid white 1px"
                  size="lg"
                  variant="danger"
                  ><router-link
                    to="/Apply"
                    style="text-decoration: none"
                    class="text-white"
                    >Aplica ahora</router-link
                  ></b-button
                >
              </b-col>
              <b-col sm="12">
                <b-container
                  fluid
                  class="h-100 d-flex flex-column justify-content-center p-0"
                >
                  <b-row>
                    <b-col sm="6" class="p-0">
                      <div
                        v-if="isMobile"
                        class="title d-flex flex-row bg-light mb-3"
                      >
                        <p
                          class="h3 text-center text-uppercase mx-auto align-self-center font-weight-light m-1"
                        >
                          girl
                          <span class="font-weight-bold text-dark">power</span>
                          talk
                          <span class="font-weight-bold text-danger"
                            >méxico</span
                          >
                        </p>
                      </div>
                      <div
                        v-if="!isMobile"
                        class="title d-flex flex-row-reverse bg-light p-2"
                      >
                        <p
                          class="  h3 text-uppercase mr-5 align-self-center font-weight-light"
                        >
                          girl
                          <span class="font-weight-bold text-dark">power</span>
                          talk
                          <span class="font-weight-bold text-danger"
                            >méxico</span
                          >
                        </p>
                      </div>
                    </b-col>
                    <b-col sm="6">
                      <div
                        style="height:4em"
                        class="d-flex flex-row ml-5"
                        :class="{
                          'mx-auto': isMobile,
                          'justify-content-center': isMobile,
                        }"
                      >
                        <p
                          class="text-white my-5 text-center h3 font-weight-bold text-uppercase align-self-center "
                        >
                          Historias de mujeres como tú
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
              <b-col sm="12" class=" d-flex flex-column justify-content-center">
                <b-row class="d-flex flex-row justify-content-center">
                  <b-col sm="8" class=" d-flex flex-row justify-content-center">
                    <fieldset class="field-container">
                      <b-form-input
                        @change="resetarticles"
                        type="text"
                        placeholder="Busca entre nuestras historias..."
                        class="field"
                        size="lg"
                        v-model="searchWords"
                      ></b-form-input>
                      <div class="icons-container">
                        <div @click="searchArticles" class="icon-search"></div>
                        <div class="icon-close">
                          <div class="x-up"></div>
                          <div class="x-down"></div>
                        </div>
                      </div>
                    </fieldset>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- SECTION All articles -->
        <b-row class="d-flex flex-row justify-content-center mt-3">
          <b-col
            v-for="(article, index) in articles"
            :key="index"
            cols="12"
            sm="4"
          >
            <articlecard :article="article" />
          </b-col>
        </b-row>
        <!-- NOTE contact -->
        <b-row class="get-in-touch-bg">
          <b-col
            style="height:14em"
            class="d-flex flex-column justify-content-center "
          >
            <div class="container">
              <div class="row my-3">
                <div class="col-12">
                  <p class="h3" :class="{ h5: isMobile }">
                    Si tienes una historia inspiradora, contáctanos.
                    #GirlPowerTalkMexico #GPTMexico
                  </p>
                </div>
              </div>
              <div class="row my-3">
                <div class="col-12">
                  <div class="btn btn-outline-danger">Contáctanos</div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <!-- SECTION CONTACT FORM  -->
        <b-row>
          <b-col cols="12">
            <contactform></contactform>
          </b-col>
        </b-row>
        <!-- SECTION FOOTER  -->
        <b-row>
          <b-col class="m-0 p-0">
            <footerComponent></footerComponent>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import sidebarcontent from "@/components/SidebarContent.vue";
import articlecard from "@/components/ArticlesComponents/ArticleCard.vue";
import db from "../components/firebaseInit";
import contactform from "@/components/ContactForm.vue";
import footerComponent from "@/components/Footer.vue";

export default {
  name: "Articles",
  components: { sidebarcontent, articlecard, contactform, footerComponent },
  data() {
    return {
      isMobile: false,
      playingVideo: false,
      articles: [],
      searchWords: "",
    };
  },

  created() {
    this.getArticulos();
    // window.addEventListener("scroll", this.handleScroll);
    this.isMobile = window.screen.width <= 540 ? true : false;
  },
  methods: {
    resetarticles() {
      if (this.searchWords.length <= 0) {
        this.getArticulos();
      }
    },

    searchArticles() {
      console.log(this.searchWords.split(" "));
      let articulosRef = db.collection("articles");

      let query = articulosRef.where(
        "search",
        "array-contains-any",
        this.searchWords.split(" ")
      );

      query
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            console.log("fuck");
          } else {
            let searchedArticles = [];
            querySnapshot.forEach((doc) => {
              searchedArticles.push(doc.data());
            });
            this.articles = [...searchedArticles];
          }
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
      // let query = articulosRef
      //   .where("title", ">=", this.searchWords)
      //   .where("title", "<=", this.searchWords + "\uf8ff");
    },

    getArticulos() {
      // Defino mi promesa
      let getArticles = () => {
        return new Promise((resolve, reject) => {
          db.collection("articles")
            .get()
            .then((querySnapshot) => {
              let articlesArray = [];
              querySnapshot.forEach((doc) => {
                articlesArray.push(doc.data());
              });
              resolve(articlesArray);
            })
            .catch((error) => {
              console.log(`Ocurrió un error: ${error}`);
              reject("error");
            });
        });
      };

      // Con la respuesta igualo el array de articulos local
      getArticles()
        .then((resp) => {
          this.articles = [...resp];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    playPauseVideo() {
      this.playingVideo === true
        ? this.$refs.landingVideo.pause()
        : this.$refs.landingVideo.play();
      this.showSidebar = !this.showSidebar;
      this.playingVideo = !this.playingVideo;
    },
  },
};
</script>

<style scoped>
@import url(https://fonts.googleapis.com/css?family=Montserrat);
@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes color-1 {
  0% {
    background-color: #eb73b9;
  }
  100% {
    background-color: #17ead9;
  }
}
@keyframes color-1 {
  0% {
    background-color: #eb73b9;
  }
  100% {
    background-color: #17ead9;
  }
}
::-moz-selection {
  background: #6078ea;
  color: white;
  text-shadow: none;
}
::selection {
  background: #6078ea;
  color: white;
  text-shadow: none;
}

::-webkit-selection {
  background: #6078ea;
  color: white;
  text-shadow: none;
}

body.is-focus {
  background-color: #17ead9;
}

.field-container {
  position: relative;
  padding: 0;
  margin: 0;
  border: 0;
  width: 100%;
  height: 40px;
}

.icons-container {
  position: absolute;
  top: 5px;
  right: 0px;
  width: 35px;
  height: 35px;
  overflow: hidden;
}

.icon-close {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 75%;
  height: 75%;
  opacity: 0;
  cursor: pointer;
  transform: translateX(-200%);
  border-radius: 50%;
  transition: opacity 0.25s ease,
    transform 0.43s cubic-bezier(0.694, 0.048, 0.335, 1);
}
.icon-close:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  border: 2px solid transparent;
  border-top-color: #6078ea;
  border-left-color: #6078ea;
  border-bottom-color: #6078ea;
  transition: opacity 0.2s ease;
}
.icon-close .x-up {
  position: relative;
  width: 100%;
  height: 50%;
}
.icon-close .x-up:before {
  content: "";
  position: absolute;
  bottom: 2px;
  left: 3px;
  width: 50%;
  height: 2px;
  background-color: #6078ea;
  transform: rotate(45deg);
}
.icon-close .x-up:after {
  content: "";
  position: absolute;
  bottom: 2px;
  right: 0px;
  width: 50%;
  height: 2px;
  background-color: #6078ea;
  transform: rotate(-45deg);
}
.icon-close .x-down {
  position: relative;
  width: 100%;
  height: 50%;
}
.icon-close .x-down:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 4px;
  width: 50%;
  height: 2px;
  background-color: #6078ea;
  transform: rotate(-45deg);
}
.icon-close .x-down:after {
  content: "";
  position: absolute;
  top: 5px;
  right: 1px;
  width: 50%;
  height: 2px;
  background-color: #6078ea;
  transform: rotate(45deg);
}
.is-type .icon-close:before {
  opacity: 1;
  -webkit-animation: spin 0.85s infinite;
  animation: spin 0.85s infinite;
}
.is-type .icon-close .x-up:before,
.is-type .icon-close .x-up:after {
  -webkit-animation: color-1 0.85s infinite;
  animation: color-1 0.85s infinite;
}
.is-type .icon-close .x-up:after {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.is-type .icon-close .x-down:before,
.is-type .icon-close .x-down:after {
  -webkit-animation: color-1 0.85s infinite;
  animation: color-1 0.85s infinite;
}
.is-type .icon-close .x-down:before {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.is-type .icon-close .x-down:after {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.icon-search {
  position: relative;
  top: 5px;
  left: 8px;
  width: 50%;
  height: 50%;
  opacity: 1;
  border-radius: 50%;
  border: 3px solid #c7d0f8;
  transition: opacity 0.25s ease,
    transform 0.43s cubic-bezier(0.694, 0.048, 0.335, 1);
}
.icon-search:after {
  content: "";
  position: absolute;
  bottom: -9px;
  right: -2px;
  width: 4px;
  border-radius: 3px;
  transform: rotate(-45deg);
  height: 10px;
  background-color: #c7d0f8;
}

.field {
  border: 0;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  background: white;
  border-radius: 3px;
  box-shadow: 0px 8px 15px rgba(75, 72, 72, 0.1);
  transition: all 0.4s ease;
}
.field:focus {
  outline: none;
  box-shadow: 0px 9px 20px rgba(75, 72, 72, 0.3);
}
.field:focus + .icons-container .icon-close {
  opacity: 1;
  transform: translateX(0);
}
.field:focus + .icons-container .icon-search {
  opacity: 0;
  transform: translateX(200%);
}
.custom-bg {
  background-image: linear-gradient(#e41a1ade, #e41a1ade),
    url("https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/timeline%2F2021_febrero.jpg?alt=media&token=35e9151f-32bd-451f-b650-5fa9004be8f3");
  background-position: center;
}
</style>

<template>
  <b-card :img-src="article.img" img-alt="Image" img-top>
    <b-card-text style="height:8em">
      <p class="h5">{{ article.title }}</p>
      <p style="font-size:smaller">
        {{ article.description }}
      </p>
    </b-card-text>
    <template #footer>
      <b-icon icon="clock"></b-icon>
      <small class="text-muted ml-2">
        {{ article.date.toDate().getDate() }} /
        {{ article.date.toDate().getDay() }} /
        {{ article.date.toDate().getFullYear() }}
      </small>
    </template>
  </b-card>
</template>

<script>
export default {
  name: "ArticleCard",
  props: ["article"],
  data() {
    return {};
  },
  created() {},
};
</script>

<style scoped></style>
